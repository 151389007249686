import Vue from 'vue'
import VueI18n from 'vue-i18n'

import eleZh from 'element-ui/lib/locale/lang/zh-CN'
import eleEn from 'element-ui/lib/locale/lang/en'

import enLocale from './en.json'
import zhLocale from './zh-CN.json'
import zhTWLocale from './zh-tw.json'

import store from '@/store'
import { upperFirst } from 'lodash-es'

const enCache = {}
const cnCache = {}
const cnTWCache = {}
function importAll (r, cache) {
  r.keys().forEach((key) => (cache[key] = r(key)))
}
importAll(require.context('./', true, /en\.json$/), enCache)
importAll(require.context('./', true, /cn\.json$/), cnCache)
importAll(require.context('./', true, /tw\.json$/), cnTWCache)
Vue.use(VueI18n)

const messages = {
  en: {
    ...enLocale,
    ...eleEn,
    ...Object.values(enCache).reduce((acc, module) => {
      return { ...acc, ...module }
    }, {})
  },
  'zh-CN': {
    ...zhLocale,
    ...eleZh,
    ...Object.values(cnCache).reduce((acc, module) => {
      return { ...acc, ...module }
    }, {})
  },
  'zh-tw': {
    ...zhTWLocale,
    ...eleZh,
    ...Object.values(cnTWCache).reduce((acc, module) => {
      return { ...acc, ...module }
    }, {})
  }
}

class CustomFormatter {
  constructor () {
    this._caches = Object.create(null)
  }

  interpolate (message, values) {
    if (!values) {
      return upperFirst(message)
    }

    let tokens = this._caches[message]
    if (!tokens) {
      tokens = parse(message)
      this._caches[message] = tokens
    }
    if (!values) return [tokens]
    else upperFirst(compile(tokens, values).reduce((pre, nex) => pre + nex))
  }
}

const i18n = new VueI18n({
  locale: (store && store.state.app && store.state.app.lang) || 'zh-CN',
  messages,
  silentFallbackWarn: true,
  silentTranslationWarn: true,
  formatter: new CustomFormatter()
})

export {
  i18n as default
}

const RE_TOKEN_LIST_VALUE = /^(?:\d)+/
const RE_TOKEN_NAMED_VALUE = /^(?:\w)+/

// 拆分变量
function parse (format) {
  const tokens = []
  let position = 0

  let text = ''
  while (position < format.length) {
    let char = format[position++]
    if (char === '{') {
      if (text) {
        tokens.push({ type: 'text', value: text })
      }

      text = ''
      let sub = ''
      char = format[position++]
      while (char !== undefined && char !== '}') {
        sub += char
        char = format[position++]
      }
      const isClosed = char === '}'

      const type = RE_TOKEN_LIST_VALUE.test(sub)
        ? 'list'
        : isClosed && RE_TOKEN_NAMED_VALUE.test(sub)
          ? 'named'
          : 'unknown'
      tokens.push({ value: sub, type })
    } else if (char === '%') {
      if (format[(position)] !== '{') {
        text += char
      }
    } else {
      text += char
    }
  }

  text && tokens.push({ type: 'text', value: text })

  return tokens
}
function isObject (obj) {
  return obj !== null && typeof obj === 'object'
}

function compile (tokens, values) {
  const compiled = []
  let index = 0

  const mode = Array.isArray(values)
    ? 'list'
    : isObject(values)
      ? 'named'
      : 'unknown'
  if (mode === 'unknown') { return compiled }

  while (index < tokens.length) {
    const token = tokens[index]
    switch (token.type) {
      case 'text':
        compiled.push(token.value)
        break
      case 'list':
        compiled.push(values[parseInt(token.value, 10)])
        break
      case 'named':
        if (mode === 'named') {
          compiled.push((values)[token.value])
        } else {
          if (process.env.NODE_ENV !== 'production') {
            console.warn(`Type of token '${token.type}' and format of value '${mode}' don't match!`)
          }
        }
        break
      case 'unknown':
        if (process.env.NODE_ENV !== 'production') {
          console.warn('Detect \'unknown\' type of token!')
        }
        break
    }
    index++
  }

  return compiled
}
