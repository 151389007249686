<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
import { getElementStatusInViewPort } from '@/utils/viewport'
export default {
  data () {
    return {
    }
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler () {
        this.$nextTick(() => {
          this.addAnimation()
        })
      }
    }
  },
  created () {
  },
  mounted () {
    // 页面刷新时 不停留在当前位置 返回页面顶部。该方式主要是为了使得getBoundingClientRect方法获取的距离是正确的初始化数值。
    if (history.scrollRestoration) {
      history.scrollRestoration = 'manual'
    }
    window.addEventListener('load', () => {
      this.addAnimation()
    })
    // window.addEventListener('scroll', () => {
    //   this.addAnimation()
    // })
    window.addEventListener('scroll', this.fnThrottle(this.addAnimation, 10))
  },
  methods: {
    fnThrottle (fn, delay, atleast) {
      let timer = null
      let previous = null
      return function () {
        const now = +new Date()
        if (!previous) previous = now
        if (atleast && now - previous > atleast) {
          fn()
          previous = now
          clearTimeout(timer)
        } else {
          clearTimeout(timer)
          timer = setTimeout(function () {
            fn()
            previous = null
          }, delay)
        }
      }
    },
    addAnimation () {
      const containerList = [...document.querySelectorAll('.view-container')]
      for (let i = 0; i < containerList.length; i++) {
        const container = containerList[i]
        if (container.classList.contains('view-animation')) {
          continue
        } else {
          const status = getElementStatusInViewPort(container)
          if (status === 'SHOWING' || status === 'SHOWED' || status === 'LEAVING' || status === 'LEAVED') {
            container.classList.add('view-animation')
          }
        }
      }
    }
  }
}
</script>

<style lang="less">
html,
body {
  width: 100%;
  // 去除移动端点击元素时产生的阴影效果
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  scroll-behavior: smooth;
}
#app {
  min-width: 1200px;
  font-size: 24px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  .view-container {
    position: relative;
    max-width: 1200px;
    padding: 0 30px;
    margin: 0 auto;
    height: 100%;
    &.no-view-animation {
      animation: none !important;
    }
    &.view-animation {
      animation: scroll-flash 1s 1;
    }
  }
  .title {
    height: 34px;
    font-size: 34px;
    text-align: center;
    font-weight: 700;
    color: #000000;
    line-height: 34px;
  }
}

@keyframes scroll-flash {
  0% {
    // top: 100px;
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    // top: 0;
    transform: translateY(0);
    opacity: 1;
  }
}
@media screen and (max-width: @mobileWidth) {
  #app {
    min-width: 300px;
    width: 100%;
    font-size: 14px;
    .view-container {
      max-width: @mobileWidth;
      padding: 0 20px;
    }
    .title {
      font-size: 20px;
      font-weight: 700;
      line-height: 40px;
    }
  }
}
</style>
