import axios from '@/utils/http/axios'
import { CBI_CATCHER_APP } from '@/utils/http/base-url'
export default {
  // 获取国家码(已剔除风险国家)
  getCountryCode (params = {}, config = {}) {
    return axios.post(`${CBI_CATCHER_APP}/catcher/front/clues/common/getCountryCode`, params, config)
  },
  // 获取国家码(已剔除风险国家) 带ip
  getCountryCodeWithIp (params = {}, config = {}) {
    return axios.post(`${CBI_CATCHER_APP}/catcher/front/clues/common/getCountryCodeWithIp`, params, config)
  }
}
