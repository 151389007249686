<template>
  <div class="openAccount">
    <div class="openAccount-left">
      <div>{{ $t('在线办理，视频面签') }}</div>
      <div
        class="openAccount-info"
        v-html="$t('极速下户 | 低关户风险，低资金冻结风险')"
      ></div>
    </div>
    <div
      class="openAccount-btn"
      :class="{en: lang == 'en'}"
      @click="clickBtn"
    >
      {{ $t(btnText) }}
      <img
        v-if="showIcon && lang != 'en'"
        src="@/assets/images/footer/arrow.png"
        alt=""
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    showIcon: {
      type: Boolean,
      default: true
    },
    btnText: {
      type: String,
      default: '开户申请'
    }
  },
  computed: {
    ...mapGetters(['lang'])
  },
  methods: {
    clickBtn () {
      // document.body.scrollTop = document.documentElement.scrollTop = 0
      this.$emit('btnClick')
    }
  }
}
</script>

<style scoped lang="less">
.openAccount {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 68px 140px 69px 140px;
  color: #ffffff;
  font-size: 34px;
  background: linear-gradient(-90deg, #ffbb00 0%, #ff492d 100%);
  border-radius: 20px;
  .openAccount-info {
    font-size: 22px;
    line-height: 22px;
    padding-top: 30px;
  }
  .openAccount-btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 196px;
    height: 60px;
    // padding: 19px 71px 19px 35px;
    background: linear-gradient(-270deg, #ffffff 0%, #fff7e3 100%);
    border-radius: 10px 10px 10px 10px;
    color: #e4371c;
    line-height: 22px;
    font-size: 22px;
    cursor: pointer;
    animation-name: flashover;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: ease-in-out;
    img {
      width: 20px;
      height: 20px;
      margin-left: 12px;
    }
    &.en {
      padding: 0;
      &::before {
        width: 0;
      }
    }
  }
  @keyframes flashover {
    0% {
      transform: scale(0.92);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.92);
    }
  }
}
@media screen and (max-width: @mobileWidth) {
  .openAccount {
    padding: 23px 20px;
    font-size: 14px;
    border-radius: 10px;
    .openAccount-left {
      width: 230px;
    }
    .openAccount-info {
      font-size: 10px;
      line-height: 14px;
      padding-top: 10px;
    }
    .openAccount-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 90px;
      height: 30px;
      font-size: 10px;
      padding: 0;
      border-radius: 10px;
      img {
        width: 10px;
        height: 10px;
        margin-left: 5px;
      }
    }
  }
}
</style>
