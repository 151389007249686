<template>
  <!-- 底部 -->
  <div class="footer">
    <div class="view-container">
      <div class="card">
        <div class="card-item">
          <div class="card-item-title">{{ $t('客服热线') }}</div>
          <div class="card-item-content">
            <div>+1-939-332-7285（{{ $t('美国地区') }}）</div>
            <div>+86-400-022-9291（{{ $t('中国大陆地区') }}）</div>
          </div>
        </div>
        <div class="card-item">
          <div class="card-item-title">{{ $t('联系邮箱') }}</div>
          <div class="card-item-content">
            <div>market@cbibank.com</div>
          </div>
        </div>
        <div class="card-item">
          <div class="card-item-title">{{ $t(addressTitle) }}</div>
          <div class="card-item-content">
            <div>ST 330, 270 Munoz Rivera Ave, San Juan,
             </div>
            <div>
              Puerto Rico, United States 00918
            </div>
          </div>
        </div>
        <div class="card-item">
          <div class="card-item-title card-item-btn" @click="toPrivacy">{{ $t('隐私协议') }}</div>
        </div>
      </div>
      <div class="footer-info">Copyright ©2017 CB International Bank LLC</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideConnect: {
      type: Boolean,
      default: false
    },
    addressTitle: {
      type: String,
      default: '注册地址'
    }
  },
  methods: {
    toPrivacy () {
      this.$router.push({
        name: 'Privacy',
        query: {
          ...this.$route.query
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.footer {
  padding-top: 142px;
  width: 100%;
  background-color: #000;
  overflow: hidden;
  .card {
    display: flex;
    font-size: 14px;
    color: #FFFFFF;
    .card-item {
      padding-right: 100px;
      &:last-child {
        padding-right: 0;
      }
      .card-item-title {
        font-weight: 700;
        padding-bottom: 20px;
      }
      .card-item-btn {
        cursor: pointer;
        &:hover {
          color: #C7D1FD;
        }
      }
      .card-item-content {
        font-size: 14px;
        font-weight: 300;
        line-height: 24px;
      }
    }
  }
  .footer-info {
    margin: 61px 43px;
    font-size: 12px;
    color: #FFFFFF;
    line-height: 12px;
    text-align: center;
  }
}
@media screen and (max-width: @mobileWidth) {
  .footer {
    padding-top: 70px;
    width: 100%;
    height: auto;
    .card {
      display: flex;
      flex-wrap: wrap;
      font-size: 12px;
      color: #FFFFFF;
      .card-item {
        flex: auto;
        margin-bottom: 20px;
        width: 50%;
        padding-right: 0;
        &:nth-child(even) {
          padding-left: 30px;
        }
        .card-item-title {
          padding-bottom: 12px;
        }
        .card-item-content {
          font-size: 10px;
          line-height: 20px;
        }
      }
    }
    .footer-info {
      margin: 30px 0 19px;
      font-size: 10px;
      line-height: 10px;
    }
  }
}
</style>
