<template>
  <Select
    v-bind="$attrs"
    v-on="$listeners"
    :autocomplete="$attrs.autocomplete || 'new-text'"
    :placeholder="$attrs.placeholder || $t('请选择')">
    <slot/>
  </Select>
</template>

<script>
import { Select } from 'element-ui'
export default {
  components: {
    Select
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
</style>
