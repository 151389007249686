import { render, staticRenderFns } from "./ToggleTab.vue?vue&type=template&id=98cbbeae&scoped=true&"
import script from "./ToggleTab.vue?vue&type=script&lang=js&"
export * from "./ToggleTab.vue?vue&type=script&lang=js&"
import style0 from "./ToggleTab.vue?vue&type=style&index=0&id=98cbbeae&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "98cbbeae",
  null
  
)

export default component.exports