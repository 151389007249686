<template>
  <ul class="radio-list">
    <li
      class="radio-item"
      v-for="(item, index) in radioList"
      :key="index"
      @click="handleSelect(item, index)"
      :class="{ en: lang == 'en' }"
    >
      <img
        v-show="!item.selected"
        src="@/assets/images/banner/unselected.png"
        alt=""
      >
      <img
        v-show="item.selected"
        :src="selected"
        alt=""
      >
      <span>{{ lang == 'en' ? item.descEn : lang == 'zh-tw'? item.descTw: item.desc }}</span>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    selectType: {
      type: String,
      default: ''
    },
    selectCode: {
      type: Number,
      default: 0
    },
    list: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      radioList: []
    }
  },
  watch: {
    list: {
      handler (val) {
        this.radioList = val.map((item, index) => {
          const { selectCode } = this
          return {
            ...item,
            selected: selectCode === item.code
          }
        })
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['lang']),
    selected () {
      const { selectType } = this.$props
      return selectType === 'white' ? require('@/assets/images/banner/selected-white.png') : require('@/assets/images/banner/selected.png')
    }
  },
  methods: {
    handleSelect (item, index) {
      this.radioList.forEach((radioItem, radioIndex) => {
        if (radioIndex !== index) {
          radioItem.selected = false
        } else {
          radioItem.selected = true
        }
      })
      this.$emit('update:selectCode', item.code)
    }
  }
}
</script>

<style scoped lang="less">
.radio-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  overflow: hidden;
  .radio-item {
    display: flex;
    align-items: center;
    width: 50%;
    margin-bottom: 15px;
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: normal;
    color: #182641;
    line-height: 12px;
    cursor: pointer;
    &.en {
      width: 100%;
    }
    > img {
      width: 12px;
      height: 12px;
    }
    > span {
      margin-left: 5px;
    }
  }
}
@media screen and (max-width: @mobileWidth) {
  .radio-list {
    .radio-item {
      > img {
        width: 12px;
        height: 12px;
      }
      > span {
        margin-left: 5px;
      }
    }
  }
}
</style>
