import api from '@/api/index'
import store from '@/store/index'
import geeInit from './gt.js'
export default class GeeTest {
  constructor(onClose) {
    // this.ele = ele
    this.onClose = onClose
    this.captchaObj = null
    this.r = null
    this.initGee()
  }
  initGee() {
    api.geeTestRegister().then(data => {
      if (data) {
        geeInit({
          gt: data.gt,
          challenge: data.challenge,
          new_captcha: data.new_captcha, // 用于宕机时表示是新验证码的宕机
          offline: !data.success, // 表示用户后台检测极验服务器是否宕机，一般不需要关注
          product: 'bind', // 产品形式，包括：float，popup
          width: '100%',
          lang: store.state.app.lang,
          area: this.ele, // 背景展示区域
          next_width: '300px',
          bg_color: 'black'
        }, captchaObj => {
          this.captchaObj = captchaObj
          captchaObj.onSuccess(() => {
            const result = captchaObj.getValidate()
            this.r(result)
          })
          captchaObj.onError(() => {
            this.initGee()
            this.r(false)
          })
          captchaObj.onClose(() => {
            this.onClose()
          })
        })
      }
    })
  }
  verify() {
    return new Promise((r) => {
      if (!this.captchaObj) return r(false)
      this.r = r
      this.captchaObj.verify()
    })
  }
}
