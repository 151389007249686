<template>
  <div
    class="header"
    ref="header"
  >
    <div class="view-container no-view-animation">
      <div class="content">
        <a
          href="/"
          class="link"
        ></a>

      </div>
      <template>
        <div class="right-content">
          <template v-if="showSwitchLang">
            <template v-if="!isMobile">
              <div class="switch-lang">
                <div
                  v-for="(item,index) in langList"
                  :key="item.value"
                >
                  <span @click="swichLang(item.value)">{{item.label}}</span>
                  <template v-if="index != langList.length - 1">/</template>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="switch-lang-m">
                <div
                  v-if="lang == 'en'"
                  class="en"
                  @click="swichLang('zh-CN')"
                ></div>
                <div
                  v-else
                  class="cn"
                  @click="swichLang('en')"
                ></div>
              </div>
            </template>
          </template>

          <span
            class="menu"
            :class="showTopTab ? 'rotate' : ''"
            @click="handleShowTopTab"
          >
          </span>
        </div>
      </template>

    </div>
    <transition name="drop-down">
      <div
        class="top-tab"
        v-show="showTopTab"
      >
        <ul class="tab-list">
          <li
            class="tab-item"
            :class="item.value === activeTab ? 'active' : ''"
            v-for="item in tabData"
            :key="item.value"
            @click="handleSelectTab(item)"
          >
            {{ $t(item.label) }}
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import { isMobile } from '@/utils/utils'
export default {
  props: {
    showSwitchLang: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      showTopTab: false,
      langList: [
        { label: 'ZH', value: 'zh-CN' },
        { label: 'EN', value: 'en' }
      ]
    }
  },
  computed: {
    ...mapState('tab', ['activeTab', 'tabData']),
    ...mapGetters(['lang']),
    isMobile () {
      return isMobile()
    }
  },
  mounted () {
    document.addEventListener('scroll', () => {
      // 非移动端 暂时不吸顶展示
      if (!isMobile()) {
        return
      }
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      const header = this.$refs.header
      if (!header) return
      if (scrollTop > header.offsetHeight) {
        header.classList.add('scroll')
      } else {
        header.classList.remove('scroll')
      }
    })
  },
  methods: {
    ...mapMutations('tab', ['SET_ACTIVE_TAB']),
    ...mapMutations('app', ['SET_ALL']),
    handleShowTopTab () {
      this.showTopTab = !this.showTopTab
    },
    handleSelectTab ({ value }) {
      window.$myView = value
      this.SET_ACTIVE_TAB(value)
      this.$router.push({
        name: value,
        query: {
          ...this.$route.query
        }
      })
      this.$nextTick(() => {
        this.showTopTab = false
      })
    },
    swichLang (value) {
      this.$i18n.locale = value || 'zh-CN'
      this.SET_ALL({
        lang: value
      })
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  position: relative;
  height: 72px;
  margin-bottom: -72px;
  padding: 11px 0;
  background-color: transparent;
  z-index: 2000;
  transition: opacity 0.4s, transform 0.4s;
  .view-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .switch-lang {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80px;
      height: 26px;
      border: 1px solid #ffffff;
      color: #ffffff;
      font-size: 14px;
      border-radius: 200px;
      user-select: none;
      font-family: Source Han Sans CN-Light, Source Han Sans CN;
      span {
        cursor: pointer;
      }
    }
    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      .link {
        width: 170px;
        height: 50px;
        background-image: url('~assets/images/header/logo_t.png');
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
    .right-content {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .menu {
        display: none;
        width: 24px;
        height: 24px;
        background-image: url('~assets/images/header/menu.png');
        background-repeat: no-repeat;
        background-size: cover;
        cursor: pointer;
        transition: transform 0.4s ease-in-out;
        &.rotate {
          transform: rotate(90deg);
        }
      }
    }
  }

  &.scroll {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: #ffffff;
    box-shadow: 0px 4px 14px 0px rgba(56, 69, 212, 0.1);
    .content {
      .link {
        background-image: url('~assets/images/header/logo.png');
      }
    }
  }
  .top-tab {
    display: none;
    transition: all 0.5s ease-in-out;
  }
}
@headerHeight: 64px;
@media screen and (max-width: @mobileWidth) {
  .header {
    height: @headerHeight;
    margin-bottom: -@headerHeight;
    padding: 6px 0;
    .view-container {
      .switch-lang {
        width: 120px;
        height: 40px;
        color: #ffffff;
        font-size: 26px;
        border-radius: 200px;
        user-select: none;
        font-family: Source Han Sans CN-Light, Source Han Sans CN;
        span {
          cursor: pointer;
        }
      }
      .switch-lang-m {
        margin-right: 10px;
        div {
          width: 24px;
          height: 24px;
          &.cn {
            background: url('~assets/images/header/icon-cn.png') no-repeat
              center/cover;
          }
          &.en {
            background: url('~assets/images/header/icon-en.png') no-repeat
              center/cover;
          }
        }
      }
      .content {
        .link {
          width: 110px;
          height: 30px;
          background-image: url('~assets/images/header/m_logo_t.png');
        }
      }
      .right-content {
        .menu {
          display: block;
        }
      }
    }
    &.scroll {
      display: block;
      box-shadow: 0px 4px 14px 0px rgba(56, 69, 212, 0.1);
      .switch-lang-m {
        div {
          &.cn {
            background: url('~assets/images/header/icon-cn-b.png') no-repeat
              center/cover;
          }
          &.en {
            background: url('~assets/images/header/icon-en-b.png') no-repeat
              center/cover;
          }
        }
      }
      .content {
        .link {
          background-image: url('~assets/images/header/m_logo.png');
        }
      }
      .right-content {
        .menu {
          background-image: url('~assets/images/header/menu_t.png');
        }
      }
    }
    .top-tab {
      position: absolute;
      top: @headerHeight;
      left: 0;
      right: 0;
      z-index: 2000;
      display: block;
      width: 100vw;
      height: calc(100vh - @headerHeight);
      background: rgba(0, 0, 0, 0.6);
      cursor: pointer;
      user-select: none;
      .tab-list {
        width: 100%;
        padding: 0 20px;
        background: linear-gradient(180deg, #f0f3ff 0%, #c7d1fd 100%);
        .tab-item {
          height: 60px;
          line-height: 60px;
          text-align: center;
          font-size: 16px;
          color: #000000;
          border-bottom: 1px solid rgba(0, 0, 0, 0.16);
          &:last-child {
            border-bottom: none;
          }
          &.active {
            color: #313cb0;
          }
        }
      }
    }
  }
}
.drop-down-enter {
  opacity: 0;
  transform: translate(0px, 600px) scaleY(0.2);
}
.drop-down-leave-to {
  opacity: 0;
  transform: translate(0px, 600px) scaleY(0.2);
}
.drop-down-enter-active {
  transition: all 0.5s ease-in;
}
.drop-down-leave-active {
  transition: all 0.5s ease;
}
</style>
