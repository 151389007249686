<template>
  <div>
    <div class="title">
      {{ $t('企业服务') }}
    </div>
    <div class="card">
      <div class="card-item card-item-img-1">
        <div class="card-item-title">{{ $t('美国银行') }}</div>
        <div class="card-item-content">
          <div>{{ $t('快速便捷全流程在线申请开立美国银行账户') }}</div>
          <div>{{ $t('实时反馈开户进展') }}</div>
          <div>{{ $t('多功能账户服务，支持企业发薪') }}</div>
          <div>{{ $t('投资、B2B贸易、服务贸易等收付款') }}</div>
        </div>
      </div>
      <div class="card-item card-item-img-2">
        <div class="card-item-title">{{ $t('电商收款/结汇') }}</div>
        <div class="card-item-content">
          <div>{{ $t('支持北美主流电商、亚太海淘电商') }}</div>
          <div>{{ $t('自建站电商收付款') }}</div>
          <div>{{ $t('支持电商结汇至中国大陆') }}</div>
        </div>
      </div>
      <div class="card-item card-item-img-3">
        <div class="card-item-title">{{ $t('企业理财/资产管理') }}</div>
        <div class="card-item-content">
          <div>{{ $t('全球优质资产配置计划') }}</div>
          <div>{{ $t('美元余额宝兼顾企业流动性和收益') }}</div>
          <div>{{ $t('美元固收理财保值增值') }}</div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'CorporateServices'
}
</script>

<style scoped lang="less">
.card {
  padding-top: 60px;
  display: flex;
  .card-item {
    width: 380px;
    border-radius: 20px;
    background: linear-gradient(180deg, #FFFFFF 2%, #F5F7FD 100%);
    box-shadow: 7px 7px 0px #515AAA;
    border: 1px solid #4D5BFF;
    padding: 40px 0 35px 30px;
    position: relative;
    margin-right: 27px;
    &:last-child {
      margin-right: 0;
    }
    .card-item-title {
      width: 73%;
      font-size: 20px;
      color: #4D5BFF;
      line-height: 20px;
      font-weight: 400;
      padding-bottom: 20px;
    }
    .card-item-content {
      font-size: 15px;
      color: #1A1A1A;
      font-weight: 300;
      line-height: 30px;
    }
  }
  .card-item-img(@num)  {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100px;
    height: 100px;
    background: url('~assets/images/service/corporateServices/service@{num}.png') no-repeat center/cover;
  }
  .card-item-img-1:before {
    .card-item-img(1);
  }
  .card-item-img-3:before {
    .card-item-img(3);
  }
  .card-item-img-2:before {
    .card-item-img(2);
  }
}
@media screen and (max-width: @mobileWidth) {
  .card {
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    .card-item {
      width: 100%;
      border-radius: 20px;
      box-shadow: 0px 7px 0px #515AAA;
      padding: 20px 0 13px 15px;
      margin-right: 0;
      margin-bottom: 22px;
      &:last-child {
        margin-bottom: 0;
      }
      .card-item-title {
        font-size: 16px;
        line-height: 16px;
        padding-bottom: 15px;
      }
      .card-item-content {
        font-size: 12px;
        line-height: 24px;
      }
    }
    .card-item-img(@num)  {
      width: 50px;
      height: 50px;
    }
    .card-item-img-1:before {
      .card-item-img(1);
    }
    .card-item-img-3:before {
      .card-item-img(3);
    }
    .card-item-img-2:before {
      .card-item-img(2);
    }
  }
}

</style>
