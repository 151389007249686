export const buriedPoint = {
  // 营销站
  4 () {
    // eslint-disable-next-line camelcase
    function gtag_report_conversion (url) {
      var callback = function () {
        if (typeof (url) !== 'undefined') {
          window.location = url
        }
      }
      window.gtag && window.gtag('event', 'conversion', {
        // eslint-disable-next-line
        'send_to': 'AW-11003387122/97ZLCNeIloQYEPK56f4o',
        // eslint-disable-next-line
        'event_callback': callback
      })
      return false
    }
    gtag_report_conversion()
    // window.gtag && window.gtag('event', 'conversion', {
    //   send_to: 'AW-11003387122/97ZLCNeIloQYEPK56f4o'
    // })
    // facebook埋点
    window.fbq && window.fbq('track', 'Contact')
  },
  // 阿联酋
  5 () {
    // // eslint-disable-next-line camelcase
    // function gtag_report_conversion () {
    //   var callback = function (url) {
    //     if (typeof (url) !== 'undefined') {
    //       window.location = url
    //     }
    //   }
    //   window.gtag && window.gtag('event', 'conversion', {
    //     // eslint-disable-next-line
    //     'send_to': 'AW-11022198877/8gPVCL7X7YQYEN3Q5Ycp',
    //     // eslint-disable-next-line
    //     'event_callback': callback
    //   })

    //   return false
    // }
    // gtag_report_conversion()
    // // window.gtag && window.gtag('event', 'conversion', {
    // //   send_to: 'AW-11022198877/8gPVCL7X7YQYEN3Q5Ycp'
    // // })
    // eslint-disable-next-line camelcase
    function gtag_report_conversion (url) {
      var callback = function () {
        if (typeof (url) !== 'undefined') {
          window.location = url
        }
      }
      window.gtag && window.gtag('event', 'conversion', {
        // eslint-disable-next-line
        'send_to': 'AW-11038825726/fTccCPbggIUYEP653I8p',
        // eslint-disable-next-line
        'event_callback': callback
      })
      return false
    }
    gtag_report_conversion()
    // eslint-disable-next-line camelcase
    function gtag_report_conversion1 (url) {
      var callback = function () {
        if (typeof (url) !== 'undefined') {
          window.location = url
        }
      }
      window.gtag && window.gtag('event', 'conversion', {
        // eslint-disable-next-line
        'send_to': 'AW-11158131252/FyPqCIbMpoQYEKTs3Y8p',
        // eslint-disable-next-line
        'event_callback': callback
      })

      return false
    }
    gtag_report_conversion1()
    // facebook埋点
    window.fbq && window.fbq('track', 'CompleteRegistration')
  },
  // 港澳台
  6 () {
    // eslint-disable-next-line camelcase
    function gtag_report_conversion (url) {
      var callback = function () {
        if (typeof (url) !== 'undefined') {
          window.location = url
        }
      }
      window.gtag && window.gtag('event', 'conversion', {
        // eslint-disable-next-line
        'send_to': 'AW-11167667377/QSFoCNSDs5wYELGplM0p',
        // eslint-disable-next-line
        'event_callback': callback
      })
      return false
    }
    gtag_report_conversion()
    // facebook埋点
    window.fbq && window.fbq('track', 'CompleteRegistration')
    // 必应埋点
    // eslint-disable-next-line camelcase
    function uet_report_conversion () {
      window.uetq = window.uetq || []
      window.uetq.push('event', 'submit_lead_form', {})
    }
    uet_report_conversion()
    // 华为埋点
    window.recordTier.push({ eventName: 'formSubmit', tracking_id: '37868492' })
    // tiktok埋点
    window.ttq.track('SubmitForm', {})
  },
  'GAT_cbibyg' () {
    // eslint-disable-next-line
    function gtag_report_conversion(url) {
      var callback = function () {
        // eslint-disable-next-line
        if (typeof (url) != 'undefined') {
          window.location = url
        }
      }
      // eslint-disable-next-line
      gtag('event', 'conversion', {
        // eslint-disable-next-line
        'send_to': 'AW-11180131977/fA20CNjgoKAYEImNjdMp',
        // eslint-disable-next-line
        'event_callback': callback
      })
      return false
    }
    gtag_report_conversion()
  },
  'GAT_b_cbibyg' () {
  }
}
