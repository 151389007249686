import Vue from 'vue'
import ClickOutside from './clickoutside'
import ScrollIntoView from './scrollintoview'
// import Loading from '@/components/Loading'
import { Loading } from 'element-ui'

Vue.use(Loading.directive)
Vue.directive('clickoutside', ClickOutside)

Vue.directive('scrollintoview', ScrollIntoView)
