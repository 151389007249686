import { AES, enc } from 'crypto-js'

const currentEnvIs = function (env) {
  return process.env.VUE_APP_TARGET === env
}

// 是否启用 enableCrypto，默认开发环境关闭，其他环境开启
const enableCrypto = !currentEnvIs('DEV')

// crypto相关配置
const conf = {
  key: currentEnvIs('PROD') ? 'cce0065d125c4c94' : '0e0fd68b8e6b40f7',
  iv: currentEnvIs('PROD') ? 'b0c0d91879c7e87d' : '89729cd48caa99c4'
}
const key = enc.Utf8.parse(conf.key)
const iv = enc.Utf8.parse(conf.iv)

// 加密请求
export const encryptRequest = (request) => {
  if (!enableCrypto) {
    return request
  }
  // 如果当前加密环境不是PROD，控制台打印请求
  if (!currentEnvIs('PROD')) {
    console.log('request', request, request.data)
  }
  // 设置该响应头 后端会启用crypto
  request.headers['request-source'] = 'cbi-web-market'
  const cryptoStr = AES.encrypt(enc.Utf8.parse(JSON.stringify(request.data)), key, { iv }).toString()
  request.data = {
    seccode: cryptoStr
  }
  return request
}

// 解密响应
export const decryptResponse = (response) => {
  if (!enableCrypto) {
    return response
  }
  const bytes = AES.decrypt(response.data.seccode, key, { iv })
  response.data = JSON.parse(bytes.toString(enc.Utf8))
  // 如果当前解密环境不是PROD，控制台打印响应
  if (!currentEnvIs('PROD')) {
    console.log('response', response)
  }
  return response
}
