<template>
  <div class="news-item">
    <span v-if="!!itemData.topStatus" class="news-item-top">{{ $t('置顶') }}</span>
    <img :src="itemData.coverUrl" class="left">
    <div class="right">
      <div class="right-title">
        {{ itemData.newsTitle }}
      </div>
      <div class="right-content">{{ itemData.summary }}</div>
      <div class="right-footer">
        <div class="right-footer-left">{{ itemData.creatorUserName }}</div>
        <div class="right-footer-right">{{ $t('发表于') }}：{{ itemData.createTimeStr }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewsItem',
  props: {
    itemData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods: {

  }
}
</script>

<style scoped lang="less">
.news-item {
  padding: 30px;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  display: flex;
  cursor: pointer;
  position: relative;
  .news-item-top {
    position: absolute;
    top: 40px;
    right: 30px;
    font-size: 14px;
    font-weight: 300;
    color: #7C7C85;
    line-height: 14px;
  }
  .left {
    display: inline-block;
    width: 250px;
    height: 170px;
  }
  .right {
    padding-left: 30px;
    flex: 1;
    .right-title {
      position: relative;
      display: flex;
      padding-top: 18px;
      font-size: 20px;
      font-weight: 400;
      color: #000000;
      line-height: 20px;
      word-break: break-all;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
    .right-content {
      padding-top: 20px;
      font-size: 14px;
      font-weight: 300;
      color: #1A1A1A;
      line-height: 28px;
      word-break: break-all;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
    .right-footer {
      display: flex;
      padding-top: 25px;
      font-size: 14px;
      color: #7C7C85;
      font-weight: 300;
      line-height: 30px;
      .right-footer-left {
        position: relative;
        padding-left: 40px;
        padding-right: 50px;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 30px;
          height: 30px;
          background: url('~assets/images/news/dafu.png') no-repeat center/cover;

        }

      }
      .right-footer-right {
        position: relative;
        padding-left: 36px;
        &:before {
          content: '';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          width: 16px;
          height: 16px;
          background: url('~assets/images/news/time.png') no-repeat center/cover;

        }
      }
    }
  }
}
@media screen and (max-width: @mobileWidth) {
  .news-item {
    padding: 15px 10px 40px 10px;
    position: relative;
    .news-item-top {
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 10px;
      line-height: 10px;
    }
    .left {
      width: 100px;
      height: 75px;
    }
    .right {
      padding-left: 15px;
      flex: 1;
      .right-title {
        padding-top: 10px;
        font-size: 14px;
        font-weight: 400;
        color: #000000;
        line-height: 14px;
      }
      .right-content {
        padding-top: 10px;
        font-size: 12px;
        font-weight: 300;
        color: #1A1A1A;
        line-height: 20px;
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }
      .right-footer {
        position: absolute;
        left: 10px;
        bottom: 10px;
        display: flex;
        padding-top: 0px;
        font-size: 10px;
        color: #7C7C85;
        font-weight: 300;
        line-height: 20px;
        .right-footer-left {
          position: relative;
          padding-left: 30px;
          padding-right: 53px;
          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 20px;
            background: url('~assets/images/news/dafu.png') no-repeat center/cover;

          }

        }
        .right-footer-right {
          position: relative;
          padding-left: 24px;
          &:before {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            width: 14px;
            height: 14px;
            background: url('~assets/images/news/time.png') no-repeat center/cover;

          }
        }
      }
    }
  }
}

</style>
