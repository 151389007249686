
export default [
  {
    path: '/',
    name: 'Index',
    component: () => import('@/pages/GAT/views/layout'),
    meta: {
      loginAuth: true
    },
    redirect: '/home',
    // 承载需要layout的公共路由
    children: [
      {
        path: '/home',
        name: 'Home',
        meta: {
          title: '产品服务'
        },
        component: () => import('@/pages/home/views/home/Index')
      },
      {
        path: '/compliance',
        name: 'Compliance',
        meta: {
          title: '安全合规'
        },
        component: () => import('@/pages/home/views/compliance/Index')
      },
      {
        path: '/news',
        name: 'News',
        meta: {
          title: '新闻资讯'
        },
        component: () => import('@/pages/home/views/news/Index')
      },
      {
        path: '/news/detail',
        name: 'NewsDetail',
        meta: {
          title: '新闻资讯详情'
        },
        component: () => import('@/pages/home/views/news/Detail')
      },
      {
        path: '/wiki',
        name: 'Wiki',
        meta: {
          title: '富港智库'
        },
        component: () => import('@/pages/home/views/wiki/Index')
      },
      {
        path: '/privacy',
        name: 'Privacy',
        meta: {
          title: '隐私协议'
        },
        component: () => import('@/pages/home/views/privacy/Index')
      },
      {
        path: '/active-detail',
        name: 'ActiveDetail',
        meta: {
          title: '活动详情'
        },
        component: () => import('@/pages/home/views/active/Detail')
      }
    ]
  },
  {
    path: '*',
    redirect: '/'
  }
]
