import api from '@/utils/http/axios'
import { CBI_CATCHER_APP } from '@/utils/http/base-url'
export default {
  // 获取注册地列表
  getRegisterAreaList (params = {}, config = {}) {
    return api.post(`${CBI_CATCHER_APP}/catcher/front/clues/registerAreaList`, params, config)
  },
  // 提交线索
  submit (params = {}, config = {}) {
    return api.post(`${CBI_CATCHER_APP}/catcher/front/clues/submit`, params, config)
  },

  // 获取新闻列表
  getNewsList (params = {}, config = {}) {
    return api.post(`${CBI_CATCHER_APP}/news/newsList`, params, config)
  },
  // 获取新闻详情
  getNewsDetail (params = {}, config = {}) {
    return api.post(`${CBI_CATCHER_APP}/news/newsDetail`, params, config)
  },

  // 获取问题类型列表
  getQuestionTypeList (params = {}, config = {}) {
    return api.post(`${CBI_CATCHER_APP}/question/questionTypeList`, params, config)
  },
  // 获取问题列表
  getQuestionList (params = {}, config = {}) {
    return api.post(`${CBI_CATCHER_APP}/question/questionList`, params, config)
  },

  // 注册极验
  geeTestRegister (params = {}, config = {}) {
    return api.post(`${CBI_CATCHER_APP}/catcher/front/clues/geeTestRegister`, params, config)
  },
  // 发送短信验证码（带极验）
  sendWithGee (params = {}, config = {}) {
    return api.post(`${CBI_CATCHER_APP}/catcher/front/clues/sms/sendWithSwitch`, params, config)
  },
  // 获取所有渠道
  getAllChannel (params = {}, config = {}) {
    return api.post(`${CBI_CATCHER_APP}/catcher/front/channel/allChannel`, params, config)
  },
  // 获取所有渠道开关
  getChannelSwitch (params = {}, config = {}) {
    return api.post(`${CBI_CATCHER_APP}/catcher/front/channel/channelSwitch`, params, config)
  },
  // 获取轮播图
  getBannerList (params = {}, config = {}) {
    return api.post(`${CBI_CATCHER_APP}/banner/bannerList`, params, config)
  },
  // 轮播图详情
  getBannerDetails (params = {}, config = {}) {
    return api.post(`${CBI_CATCHER_APP}/banner/bannerDetail`, params, config)
  }
}
