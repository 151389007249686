// 获取某元素参照body的offsetTop(不适用于position:fixed;元素)
export function getOffsetTop (el) {
  const root = document.body
  let height = 0
  do {
    height += el.offsetTop
    el = el.offsetParent
  } while (el && el !== root)
  return height
}

// 获取元素距离视口的上下左右边界
export function getRect (element) {
  // 距离视窗的距离
  const rect = element.getBoundingClientRect()
  // html元素对象的上边框的宽度
  const top = document.documentElement.clientTop ? document.documentElement.clientTop : 0
  const left = document.documentElement.clientLeft ? document.documentElement.clientLeft : 0
  return {
    top: rect.top - top,
    bottom: rect.bottom - top,
    left: rect.left - left,
    right: rect.right - left
  }
}

// 获取页面滚动高度
export function getScrollTop () {
  // safari使用的是window.pageYOffset
  return document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop
}

// 判断元素是否在视口中
export function getElementStatusInViewPort (ele) {
  // 浏览器高度兼容
  const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
  const offsetHeight = ele.offsetHeight
  const rectInfo = getRect(ele)
  const { top, bottom } = rectInfo
  // 在视口之下
  if (top >= windowHeight) {
    return 'UNSHOW'
  }
  // 正在出现
  if (top < windowHeight && bottom >= windowHeight) {
    return 'SHOWING'
  }
  // 在视口中
  if (top > 0 && top < windowHeight && bottom > 0 && bottom < windowHeight) {
    return 'SHOWED'
  }
  // 正在离开视口
  if (top <= 0 && bottom <= offsetHeight && bottom > 0) {
    return 'LEAVING'
  }
  // 已经离开视口
  if (bottom <= 0) {
    return 'LEAVED'
  }
}
