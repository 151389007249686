<template>
  <div class="financialLicense">
    <div class="title">
      {{ $t('金融牌照') }}
    </div>
    <div class="card">
      <div class="card-item">
        <div class="card-item-img-1"></div>
        <div class="card-item-text">{{ $t('美国持牌银行') }}</div>
      </div>
      <div class="card-item">
        <div class="card-item-img-2"></div>
        <div class="card-item-text">{{ $t('Swift: CBLBPRSJ') }}</div>
      </div>
      <div class="card-item">
        <div class="card-item-img-3"></div>
        <div class="card-item-text">{{ $t('银联北美成员') }}</div>
      </div>
      <div class="card-item">
        <div class="card-item-img-4"></div>
        <div class="card-item-text">{{ $t('ABA: 021508125') }}</div>
      </div>
      <div class="card-item">
        <div class="card-item-img-5"></div>
        <div class="card-item-text">{{ $t('跨境人民币参与行') }}</div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'FinancialLicense'
}
</script>

<style scoped lang="less">
.card {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 60px;
  padding-left: 20px;
  font-size: 20px;
  color: #182641;
  .card-item {
    width: 200px;
    text-align: center;
    margin-right: 30px;
    &:last-child {
      margin-right: 0;
    }
    .card-item-text {
      padding-top: 63px;
      font-weight: 300;
    }
    .card-item-img(@name) {
      width: 200px;
      height: 110px;
      background: url('~assets/images/compliance/@{name}.png') no-repeat center/cover;
    }

    .card-item-img-1 {
      .card-item-img('ocif');
    }
    .card-item-img-2 {
      .card-item-img('swift');
    }
    .card-item-img-3 {
      .card-item-img('unionPay');
    }
    .card-item-img-4 {
      .card-item-img('aba');
    }
    .card-item-img-5 {
      .card-item-img('cips');
    }

  }
}
@media screen and (max-width: @mobileWidth) {
  .card {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0;
    font-size: 12px;
    color: #182641;
    height: 260px;
    &:before {
      content: '';
      position: absolute;
      top: 120px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #D8D8D8;
    }
    .card-item {
      width: 103px;
      text-align: center;
      margin-right: 0;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      &:nth-child(1) {
        top: 30px;
        margin-left: -80px;
      }
      &:nth-child(2) {
        top: 30px;
        margin-left: 80px;
      }
      &:nth-child(3) {
        top: 148px;
        margin-left: -127px;
      }
      &:nth-child(4) {
        top: 148px;
        margin-left: 0;
      }
      &:nth-child(5) {
        top: 148px;
        margin-left: 113px;
      }
      .card-item-text {
        padding-top: 20px;
      }
      .card-item-img(@name) {
        width: 74px;
        height: 40px;
        margin: 0 auto;
        background: url('~assets/images/compliance/@{name}.png') no-repeat center/cover;
      }

      .card-item-img-1 {
        position: relative;
        &:before {
          content: '';
          position: absolute;
          right: -42px;
          width: 1px;
          height: 100%;
          background-color: #D8D8D8;
        }
        .card-item-img('ocif');
      }
      .card-item-img-2 {
        .card-item-img('swift');
      }
      .card-item-img-3 {
        position: relative;
        &:before {
          content: '';
          position: absolute;
          right: -12px;
          width: 1px;
          height: 100%;
          background-color: #D8D8D8;
        }
        .card-item-img('unionPay');
      }
      .card-item-img-4 {
        position: relative;
        &:before {
          content: '';
          position: absolute;
          right: -25px;
          width: 1px;
          height: 100%;
          background-color: #D8D8D8;
        }
        .card-item-img('aba');
      }
      .card-item-img-5 {
        .card-item-img('cips');
      }

    }
  }
}

</style>
