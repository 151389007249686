const app = {
  namespaced: true,
  state: {
    lang: 'zh-CN'
  },
  mutations: {
    SET_ALL (state, data) {
      for (const k in data) {
        state[k] = data[k]
      }
    }
  },
  actions: {
  }
}

export default app
