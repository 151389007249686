<template>
  <div>
    <div class="title">
      {{ $t('为什么选择富港银行') }}
    </div>
    <div class="card">
      <div
        class="card-item"
        v-for="(itme,index) in columnList"
        :key="index"
      >
        <div
          class="card-item-title"
          :class="[`card-item-img-${index+1}`]"
        >{{ $t(itme.title) }}</div>
        <div class="card-item-content">
          <div
            v-for="desc in itme.descList"
            :key="desc"
            v-html="$t(desc)"
          ></div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'ReasonForSelection',
  data () {
    return {
      columnList: [
        {
          title: '开户便捷',
          descList: [
            '开户时间短，提交资料简捷',
            '无需关联公司、无需理财存款'
          ]
        },
        {
          title: '支持行业广泛',
          descList: [
            '支持B2B，投资，咨询，物流，电商，服务等行业'
          ]
        },
        {
          title: '支持岛国注册地开户',
          descList: [
            '支持注册在岛国公司开立银行账户（例：开曼、BVI、塞舌尔、百慕大等）'
          ]
        },
        {
          title: '公、私互转',
          descList: [
            '多功能账户支持企业账户和个人账户资金互转',
            '支持个人账户速汇至中国大陆'
          ]
        },
        {
          title: '账户安全稳定',
          descList: [
            '风控前置策略，不易被关户或资金冻结'
          ]
        },
        {
          title: '服务好',
          descList: [
            '60天新手陪伴',
            '热线、在线7*24小时中英文客服'
          ]
        },
        {
          title: '支持多类型平台收款',
          descList: [
            '支持北美电商收款（如：亚马逊、Tik Tok、Google Play、Applestore等）',
            '支持亚太海淘电商收款（如京东国际、天猫国际、拼多多等）',
            '支持支付公司提现（如：Airwallex pingpong，连连，Xtransfer等）'
          ]
        }

      ]
    }
  }
}
</script>

<style scoped lang="less">
.card {
  padding-top: 60px;
  display: flex;
  flex-wrap: wrap;
  .card-item {
    width: 380px;
    border-right: 0.5px solid;
    border-bottom: 0.5px solid;
    border-image: linear-gradient(
        270deg,
        rgba(131.00000739097595, 166.00000530481339, 255, 1),
        rgba(90.00000223517418, 120.00000044703484, 255, 1)
      )
      1 1;
    &:nth-child(3n) {
      border-right: 0;
    }
    &:last-child {
      width: 100%;
      border: 0;
    }
    .card-item-title {
      position: relative;
      padding: 20px 0 20px 90px;
      color: #4d5bff;
      font-size: 20px;
      line-height: 20px;
      background: linear-gradient(
        -270deg,
        rgba(131, 166, 255, 0.2) 0%,
        rgba(90, 120, 255, 0) 100%
      );
    }
    .card-item-content {
      padding: 20px 40px 33px 40px;
      font-size: 14px;
      line-height: 32px;
    }
  }
  .card-item-img(@name) {
    content: '';
    position: absolute;
    left: 40px;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    background: url('~assets/images/service/reasonForSelection/@{name}.png')
      no-repeat center/cover;
  }
  .card-item-img-1:before {
    .card-item-img('easyAccountOpening');
  }
  .card-item-img-2:before {
    .card-item-img('supportMore');
  }
  .card-item-img-3:before {
    .card-item-img('IslandRegistration');
  }
  .card-item-img-4:before {
    .card-item-img('Interchange');
  }
  .card-item-img-5:before {
    .card-item-img('safeAndStable');
  }
  .card-item-img-6:before {
    .card-item-img('goodService');
  }
  .card-item-img-7:before {
    .card-item-img('multipleTypes');
  }
}
@media screen and (max-width: @mobileWidth) {
  .card {
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    .card-item {
      width: 100%;
      border: none;
      .card-item-title {
        position: relative;
        padding: 12px 0 12px 49px;
        font-size: 16px;
        line-height: 16px;
        background: linear-gradient(
          -270deg,
          rgba(131, 166, 255, 0.2) 0%,
          rgba(90, 120, 255, 0) 100%
        );
      }
      .card-item-content {
        padding: 15px;
        font-size: 12px;
        line-height: 32px;
      }
    }
    .card-item-img(@name) {
      left: 15px;
      width: 24px;
      height: 24px;
    }
    .card-item-img-1:before {
      .card-item-img('easyAccountOpening');
    }
    .card-item-img-2:before {
      .card-item-img('supportMore');
    }
    .card-item-img-3:before {
      .card-item-img('IslandRegistration');
    }
    .card-item-img-4:before {
      .card-item-img('Interchange');
    }
    .card-item-img-5:before {
      .card-item-img('safeAndStable');
    }
    .card-item-img-6:before {
      .card-item-img('goodService');
    }
    .card-item-img-7:before {
      .card-item-img('multipleTypes');
    }
  }
}
</style>
