<template>
  <div
    v-show="visible"
    class="country-list-container"
  >
    <div class="country-search-container">
      <div class="country-search-input-container">
        <input
          v-model="searchCodeValue"
          class="country-search-input"
          type="text"
          maxlength="50"
          :placeholder="$t('搜索国别码')"
          @input="getSearchResult"
        >
      </div>
    </div>
    <ul
      v-clickoutside="handleClickOutside"
      class="country-list"
    >
      <li
        v-for="item in countrylist"
        :key="item.label"
        :class="{ active:item.value == selectCode || item.label == selectCode }"
        @click="handleSelect(item)"
      >
        <span class="li-left">{{ lang == 'en' ? item.nameEn : lang == 'es' ? item.nameEs : lang == "zh-tw" ? item.nameHant : item.name }}</span>
        <span class="li-right">{{ item.label }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { debounce } from 'lodash-es'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    // list: {
    //   type: Array,
    //   default () {
    //     return []
    //   }
    // },
    selectCode: {
      type: [String, Number],
      default: ''
    },
    list: {
      type: Array,
      default () {
        return []
      }
    }
  },
  watch: {
    visible () {
      this.searchCodeValue = ''
      this.countrylist = this.allCountries
    },
    list: {
      deep: true,
      handler (newV) {
        this.allCountries = newV.map(item => item)
        this.countrylist = newV.map(item => item)
      }
    }
  },
  data () {
    return {
      allCountries: [],
      countrylist: [],
      searchCodeValue: ''
    }
  },
  computed: {
    ...mapGetters(['lang'])
  },
  methods: {
    handleClickOutside () {
      this.countrylist = this.allCountries.map(item => item)
      this.searchPhoneCodeValue = ''
      this.$emit('update:visible', false)
    },
    handleSelect (item) {
      setTimeout(() => {
        this.handleClickOutside()
      })
      this.$emit('update:select-code', item.value)
    },
    getSearchResult: debounce(function () {
      this.countrylist = this.allCountries.filter(item => item.label.indexOf(this.searchCodeValue) > -1)
    }, 500)
  }
}
</script>

<style scoped lang="less">
.country-list-container {
  position: absolute;
  top: 48px;
  left: 0px;
  z-index: 100;
  border: 1px solid #d7dbec;
  width: 100%;
  box-shadow: 0px 4px 6px 0px rgba(26, 40, 77, 0.1);
  border-radius: 5px;
  padding-top: 10px;
  background: #fff;
  &:after {
    content: '';
    position: absolute;
    left: 54px;
    top: -5px;
    width: 7px;
    height: 7px;
    border-top: 1px solid #d7dbec;
    border-right: 1px solid #d7dbec;
    border-bottom: 1px solid transparent;
    border-left: 1px solid transparent;
    transform: rotate(-45deg) skew(-10deg, -10deg);
    background: #fff;
  }
  .country-search-container {
    padding: 0 10px 10px 10px;
    width: 100%;
    background: #ffffff;
    z-index: 1;

    .country-search-input-container {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        right: 12px;
        top: 50%;
        width: 22px;
        height: 22px;
        background-image: url('~@/assets/images/icon/icon-search.png');
        background-size: 100% 100%;
        transform: translateY(-50%);
      }

      .country-search-input {
        width: 100%;
        padding: 14.5px 20px;
        color: #0b0923;
        font-size: inherit;
        background: #fff;
        border-radius: 5px;
        border: 1.5px solid #f0eff2;
        box-sizing: border-box;
        font-size: 15px;
      }
    }
  }
  .country-list {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 0;
    max-height: 230px;
    overflow: scroll;
    cursor: pointer;
    z-index: 101;
    li {
      position: relative;
      padding: 0 20px 0 30px;
      height: 40px;
      line-height: 40px;
      color: #000000;
      font-size: 15px;
      .li-left {
        float: left;
      }
      .li-right {
        float: right;
        color: #787993;
      }
      &.active {
        &:after {
          content: '';
          position: absolute;
          left: 15px;
          top: 50%;
          width: 3px;
          height: 6px;
          transform: translateY(-95%) rotate(45deg);
          border-right: 2px solid #4d63f8;
          border-bottom: 2px solid #4d63f8;
        }
      }
      &:hover {
        background: #f3f3f9;
      }
    }
  }
}
</style>
