import api from '@/api'
import commonApi from '@/api/common'

import GeeTest from '@/plugins/geeTest'
import { mapGetters } from 'vuex'
import CheckPhoneMessageDialog from '@/components/common/CheckPhoneMessageDialog'
import { pageTypeObj } from '@/utils/enum'
import { buriedPoint } from '@/utils/buriedPoint'
import { getHrefParams } from '@/utils/utils'

export default {
  components: { CheckPhoneMessageDialog },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    // 1-h5落地页 2-cbibank 3-bankdo 4-营销站 5-阿联酋
    pageType: {
      type: String,
      default: '4'
    },
    // 是否展示关闭按钮
    showCloseBtn: {
      type: Boolean,
      default: true
    },
    // 埋点项目，空则用pageType
    pageProjectName: {
      type: String,
      default: ''
    },
    // 不使用传入的countryCode，采用后端接口返回
    // countryCode: {
    //   type: String,
    //   default: '86'
    // },
    cNum: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      formData: {
        cstName: '',
        countryCode: '',
        mobileNo: '',
        email: '',
        enterpriseName: '',
        areaCode: ''
      },
      radioList: [],
      channelInfo: {},

      geeInfo: {},

      dialogVisible: false,
      disabledButton: false,
      showCountryList: false,
      countryList: [],
      countryCode: '',
      loading: false
    }
  },
  created () {
    // this.formData.countryCode = this.$props.countryCode
    this.$GeeTest = new GeeTest(() => {
      this.disabledButton = false
    })
    const { pageType } = this.$props
    api.getChannelSwitch({
      pageType
    }).then(res => {
      if (res) {
        const { channelNum } = this.getInfo
        this.channelInfo = res.find(value => value.channelNum === channelNum)
        // this.getRegisterAreaList()
      }
    }).catch(console.error)
    this.$nextTick(() => {
      this.loading = true
      commonApi.getCountryCodeWithIp({
        pageNum: pageType
      }).then(res => {
        this.countryList = res.countryCodeList || []
        console.log(res.defaultCode)
        this.countryCode = res.defaultCode ? String(res.defaultCode) : 'none'
        this.formData.countryCode = this.countryCode
      }).catch(console.error).finally(() => {
        this.loading = false
      })
    })
  },
  watch: {
    visible () {
      // this.formData.countryCode = this.$props.countryCode
      this.dialogVisible = false
    },
    lang () {
      this.$GeeTest = new GeeTest(() => {
        this.disabledButton = false
      })
    }
  },
  computed: {
    ...mapGetters(['lang']),
    getInfo () {
      const channelAccount = sessionStorage.getItem('channelAccount') || this.$route.query.channelAccount
      const creativityId = sessionStorage.getItem('creativityId') || this.$route.query.creativityId
      // 如果是cbiapp，进行写死操作
      const obj = {
        ...getHrefParams(),
        ...this.$route.query
      }
      console.log(obj)
      let channelNum = sessionStorage.getItem('channelNum') || obj.channelNum || obj.cNum || this.$props.cNum
      console.log(this.$props.cNum)
      if (navigator.userAgent.toLowerCase().indexOf('cbibank') > -1) {
        channelNum = 'AP4012'
      }
      if (!channelNum) {
        const { pageType } = this.$props
        channelNum = pageTypeObj[pageType] || ''
      }
      return {
        channelAccount,
        creativityId,
        channelNum
      }
    }
  },
  methods: {
    handleShowCountryList () {
      const { loading } = this
      if (loading) return
      this.showCountryList = true
    },
    // getRegisterAreaList () {
    //   const { pageType } = this.$props
    //   api.getRegisterAreaList({
    //     pageType
    //   }).then(res => {
    //     if (res) {
    //       this.radioList = res
    //       this.formData.areaCode = res.length ? res[0].code : 0
    //     }
    //   }).catch(console.error)
    // },
    handleSubmit () {
      if (this.disabledButton) return

      const appendToElement = this.$refs.form
      const { cstName, countryCode, mobileNo, email, enterpriseName } = this.formData
      if (!cstName) {
        this.$toast({
          message: this.$t('请输入姓名'),
          appendToElement
        })
        return
      }
      if (countryCode === '' || countryCode === 'none') {
        this.$toast({
          message: this.$t('请选择国别码'),
          appendToElement
        })
        return
      }
      if (!mobileNo) {
        this.$toast({
          message: this.$t('请输入手机号码'),
          appendToElement
        })
        return
      }
      if (this.channelInfo && this.channelInfo.emailCheckStatus === 1 && email === '') {
        this.$toast({
          message: this.$t('请填写邮箱地址'),
          appendToElement
        })
        return
      }
      if (email !== '' && email.indexOf('@') < 0) {
        this.$toast({
          message: this.$t('请输入正确的邮箱地址'),
          appendToElement
        })
        return
      }
      if (this.channelInfo && this.channelInfo.corNameStatus === 1 && enterpriseName === '') {
        this.$toast({
          message: this.$t('请输入企业名称'),
          appendToElement
        })
        return
      }

      // 极验开
      if (this.channelInfo && this.channelInfo.geeStatus === 1) {
        this.$GeeTest.verify().then(res => {
          this.geeInfo = res
          // 短信开
          if (this.channelInfo && this.channelInfo.smsStatus === 1) {
            this.dialogVisible = true
          } else {
            // 短信关
            this.onPhoneCodeCheck(false)
          }
        }).catch(console.error)
      } else {
        // 极验关
        // 短信开
        if (this.channelInfo && this.channelInfo.smsStatus === 1) {
          this.dialogVisible = true
        } else {
          this.onPhoneCodeCheck(false)
        }
      }
    },

    onPhoneCodeCheck (verificationCode) {
      const { channelAccount, creativityId, channelNum } = this.getInfo

      const { cstName, countryCode, mobileNo, email, enterpriseName, areaCode } = this.formData
      const { pageProjectName, pageType } = this.$props
      const options = {
        // 渠道链接
        channeLink: window.location.href,
        // 账户id
        channelAccount,
        // 渠道id
        channelNum,
        // 创意id
        creativityId,
        // 客户名称
        customerName: cstName,
        // 客户手机号
        phone: mobileNo,
        // 邮箱
        email,
        // // 图片验证码
        // verifyCode,
        // 企业名称
        enterpriseName,
        // 注册地
        registerArea: areaCode,
        // 手机号国家区号
        countryCode,
        // 手机验证码
        verificationCode: '',
        pageType
      }
      if (verificationCode) {
        options.verificationCode = verificationCode
      }
      if (this.geeInfo && Object.keys(this.geeInfo).length) {
        options.seccode = this.geeInfo.geetest_seccode
        options.challenge = this.geeInfo.geetest_challenge
        options.validate = this.geeInfo.geetest_validate
      }
      this.disabledButton = true
      api.submit(options).then(res => {
        if (res) {
          if (this.dialogVisible) {
            this.dialogVisible = false
          }
          this.$toast({
            message: this.$t('申请成功，我们会尽快联系您')
          })
          // 调用埋点
          if (pageProjectName) {
            buriedPoint[pageProjectName] && buriedPoint[pageProjectName]({ channelNum })
          } else {
            buriedPoint[pageType] && buriedPoint[pageType]({ channelNum })
          }
          this.sendFail()
        }
      }).catch((err) => {
        console.error(err)
        if (err.retCode === '10007') {
          this.sendFail()
        }
      }).finally(() => {
        this.disabledButton = false
      })
    },
    handleClose () {
      this.dialogVisible = false
      this.$emit('update:visible', false)
    },
    sendFail () {
      this.formData = {
        ...this.formData,
        cstName: '',
        countryCode: this.countryCode,
        mobileNo: '',
        email: '',
        enterpriseName: ''
      }
      this.handleClose()
    }
  }
}
