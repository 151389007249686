import axios from 'axios'
import qs from 'qs'
import Vue from 'vue'
import store from '@/store'
import { forOwn } from 'lodash-es'
import { checkIsJSON } from '@/utils/utils'
import { encryptRequest, decryptResponse } from '@/utils/crypto'

const toast = function (message) {
  import('@/lang').then(({ default: i18n }) => {
    Vue.prototype.$toast({
      message: i18n.t(message)
    })
  })
}

const service = axios.create({
  // 项目采用的是history路由 BASE_URL会与publicPath保持一致
  baseURL: process.env.BASE_URL,
  timeout: 120000 // 请求超时
})

// request拦截器
service.interceptors.request.use(request => {
  request = encryptRequest(request)

  const LANG = store.state.app.lang === 'en' ? 'en_US'
    : store.state.app.lang === 'zh-tw' ? 'zh_TW' : 'zh_CN'
  request.headers['Accept-Language'] = LANG
  return request
}, error => {
  console.warn(error) // for debug
  Promise.reject(error)
})

// response 拦截器
service.interceptors.response.use(async response => {
  response = decryptResponse(response)
  const { data } = response

  if (data.constructor === Blob) {
    if (data.text() && checkIsJSON(await data.text())) {
      const text = JSON.parse(await data.text())
      if (text.retCode !== '0000') {
        toast(text.retMsg)
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject(false)
      }
    }
    return data
  } else {
    if (data.retCode === '0000') {
      return data.data || data.body || data || true
    } else {
      toast(data.retMsg)
      return Promise.reject(data)
    }
  }
},
error => {
  if (error.message.includes('timeout')) { // 判断请求异常信息中是否含有超时timeout字符串
    console.error('Network timeout')
    toast('Network timeout')
    return Promise.reject(error)
  }
  console.warn('err' + error) // for debug
  // toast(error)
  return Promise.reject(error)
})

// 由于axios的get和post方法传递参数有差异 进行二次封装下
const get = (url, params, config) => {
  return service({
    url,
    method: 'get',
    params,
    // 由于部分后端接口的get形式需要声明'Content-Type': 'application/json'，这里默认传一个data，axios会默认设置JSON。而如果不传data的话，axios会默认把自定义的Content-Type删除
    data: {},
    ...config
  })
}
const post = (url, params = {}, config, dataType = 'JSON') => {
  let data = {
    ...params
  }
  // 这里做一层FormData URLEncoded兼容判断
  if (dataType === 'URLEncoded') {
    // 利用qs对参数进行编码，并设置Content-Type为application/x-www-form-urlencoded
    if (Object.values(params).some(Array.isArray)) {
      // 存在值为数组的参数
      let queryString = ''
      forOwn(params, (value, key) => {
        if (Array.isArray(value)) {
          value.forEach(subValue => {
            queryString += (`${encodeURIComponent(key)}=${encodeURIComponent(subValue)}&`)
          })
        } else {
          queryString += (`${encodeURIComponent(key)}=${encodeURIComponent(value)}&`)
        }
      })
      // 去除末尾&
      data = queryString.slice(0, -1)
    } else {
      data = qs.stringify(params)
    }

    const headers = config.headers || {}
    config = {
      ...config,
      headers: {
        ...headers,
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
      }
    }
  }
  if (dataType === 'FormData') {
    data = new FormData()
    for (const k in params) {
      data.append(k, params[k])
    }
  }
  return service({
    url,
    method: 'post',
    data,
    ...config
  })
}
// 文件
const file = (url, formData, config) => {
  return service({
    url,
    method: 'post',
    // 如果是formData形式的post请求 注意这里的参数形式。因为new FormData()后即使执行append，实例formData依然是一个空对象。如果利用上面es6的语法，参数data自然也是空对象。理想情况应该是file：(binary)。
    data: formData,
    ...config
  })
}

export default {
  get,
  post,
  file
}
