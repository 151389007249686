<template>
  <Input
    v-bind="$attrs"
    v-on="$listeners"
    :autocomplete="$attrs.autocomplete || 'new-text'"
    :placeholder="$attrs.placeholder || $t('请输入')">
    <slot/>
  </Input>
</template>

<script>
import { Input } from 'element-ui'
export default {
  components: {
    Input
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
</style>
