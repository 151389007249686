<template>
  <div>
    <div class="title">
      {{ $t('团队背景') }}
    </div>
    <div class="card">
      <div class="card-item">
        <div class="card-item-img card-item-img-1"></div>
        <div class="card-item-text card-item-text-1">{{ $t('资金') }}</div>
      </div>
      <div class="card-item">
        <div class="card-item-img card-item-img-2"></div>
        <div class="card-item-text card-item-text-2">{{ $t('资产') }}</div>
      </div>
      <div class="card-item mr60">
        <div class="card-item-img card-item-img-3"></div>
        <div class="card-item-text card-item-text-3">{{ $t('信息流') }}</div>
      </div>
      <div class="card-item mr60">
        <div
          class="card-item-img-4 card-item-img-center"
          :class="{en: lang == 'en'}"
        ></div>
        <div class="card-item-text card-item-text-4">{{ $t('Pay&Fintech') }}</div>
      </div>
      <div class="card-item">
        <div class="card-item-img card-item-img-5"></div>
        <div class="card-item-text card-item-text-5">{{ $t('投资') }}</div>
      </div>
      <div class="card-item">
        <div class="card-item-img card-item-img-6"></div>
        <div class="card-item-text card-item-text-6">{{ $t('大数据') }}</div>
      </div>
      <div class="card-item">
        <div
          class="card-item-img card-item-img-7"
          :class="{ en: lang == 'en' }"
        ></div>
        <div class="card-item-text card-item-text-7">{{ $t('人工智能') }}</div>
      </div>
    </div>
    <div
      class="card-footer"
      :class="{ en: lang == 'en' }"
    >
      <div class="card-footer-title">{{ $t('团队背景') }}</div>
      <ul class="card-footer-list">
        <li class="card-footer-item">
          <span>1</span>
          {{ $t('商业银行') }}
        </li>
        <li class="card-footer-item">
          <span>2</span>
          {{ $t('财富管理') }}
        </li>
        <li class="card-footer-item">
          <span>3</span>
          {{ $t('普惠金融') }}
        </li>
        <li class="card-footer-item">
          <span>4</span>
          {{ $t('移动支付') }}
        </li>
      </ul>
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'TeamBackground',
  computed: {
    ...mapGetters(['lang'])
  }
}
</script>

<style scoped lang="less">
.card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 60px;
  padding: 50px 66px 36px 66px;
  font-size: 14px;
  border-radius: 20px 20px 0 0;
  background: #fff url('~assets/images/service/teamBackground/bg.png') no-repeat
    center/cover;
  .card-item {
    text-align: center;
    flex: 1;
    .card-item-text {
      padding-top: 20px;
      width: 100%;
    }
    &:last-child {
      &.en {
        flex: inherit;
        margin-right: 0;
      }
    }
    .card-item-img(@name) {
      margin: 0 auto;
      width: 60px;
      height: 60px;
      background: url('~assets/images/service/teamBackground/@{name}.png')
        no-repeat center/cover;
    }
    .card-item-img-1 {
      .card-item-img('funds');
    }
    .card-item-img-2 {
      .card-item-img('protection');
    }
    .card-item-img-3 {
      .card-item-img('cloud');
    }
    .card-item-img-4 {
      .card-item-img('payFintech');
      &.en {
        .card-item-img('payFintech-en');
      }
      &.card-item-img-center {
        width: 268px;
        height: 116px;
      }
    }
    .card-item-img-5 {
      .card-item-img('investment');
    }
    .card-item-img-6 {
      .card-item-img('bigData');
    }
    .card-item-img-7 {
      .card-item-img('user');
    }
  }
}
.card-footer {
  display: flex;
  align-items: center;
  border-radius: 0 0 20px 20px;
  color: #ffffff;
  padding: 28px 0 28px 30px;
  background: #4450b9 url('~assets/images/service/teamBackground/footerBg.png')
    no-repeat center/cover;
  font-size: 22px;
  line-height: 22px;
  .card-footer-list {
    display: flex;
    align-items: center;
    .card-footer-item {
      padding-left: 92px;
      position: relative;
      font-size: 16px;
      span {
        position: absolute;
        left: 60px;
        width: 22px;
        height: 22px;
        font-size: 14px;
        border-radius: 50%;
        background-color: #fff;
        color: #4d5bff;
        text-align: center;
        line-height: 22px;
      }
    }
  }
}
@media screen and (max-width: @mobileWidth) {
  .card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    padding: 27px 10px 24px 7px;
    font-size: 10px;
    .card-item {
      text-align: center;
      position: relative;
      padding-bottom: 20px;
      flex: 1;
      .card-item-text {
        padding-top: 10px;
        position: absolute;
        width: 80px;
        left: 50%;
        transform: translateX(-50%) scale(0.8);
        &-4 {
          padding-top: 13px;
        }
      }
      &:last-child {
        flex: 1;
      }
      .card-item-img(@name) {
        display: inline-block;
        width: 30px;
        height: 30px;
        background: url('~assets/images/service/teamBackground/@{name}.png')
          no-repeat center/cover;
      }
      .card-item-img-1 {
        .card-item-img('funds');
      }
      .card-item-img-2 {
        .card-item-img('protection');
      }
      .card-item-img-3 {
        .card-item-img('cloud');
      }
      .card-item-img-4 {
        .card-item-img('payFintech');
        &.en {
          .card-item-img('payFintech-en');
        }
        &.card-item-img-center {
          width: 72px;
          height: 42px;
        }
      }
      .card-item-img-6 {
        .card-item-img('bigData');
      }
      .card-item-img-7 {
        .card-item-img('user');
      }
      .card-item-img-5 {
        .card-item-img('investment');
      }
    }
  }
  .card-footer {
    padding: 16px 20px;
    font-size: 12px;
    line-height: 14px;
    &.en {
      padding: 16px 10px;
      .card-footer-list {
        .card-footer-item {
          padding-left: 36px;
          span {
            left: 22px;
            transform: scale(0.9);
          }
        }
      }
    }
    .card-footer-title {
      width: 52px;
    }
    .card-footer-list {
      flex: 1;
      flex-wrap: wrap;
      .card-footer-item {
        width: 50%;
        margin-bottom: 14px;
        padding-left: 68px;
        font-size: 12px;
        &:nth-last-child(-n + 2) {
          margin-bottom: 0;
        }
        span {
          position: absolute;
          left: 46px;
          width: 12px;
          height: 12px;
          font-size: 10px;
          line-height: 12px;
        }
      }
    }
  }
}
</style>
