<template>
  <div class="news-load">
    <span v-if="status === 'more'" class="news-load-btn more" @click="clickLoad">
      {{ $t('更多') }}
    </span>
    <span v-if="status === 'load'" class="news-load-btn load">
      {{ $t('加载中') }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'LoadMore',
  props: {
    status: {
      type: String,
      default: 'more'
    }
  },
  methods: {
    clickLoad () {
      this.$emit('clickLoad')
    }
  }
}
</script>

<style scoped lang="less">
.news-load {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95px;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  .news-load-btn {
    padding-right: 26px;
    color: #1A1A1A;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
  }
  .more {
    position: relative;
    cursor: pointer;
    &:before {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 16px;
      height: 16px;
      background: url('~assets/images/news/moreArrows.png') no-repeat center/cover;
    }
    &:hover {
      color: #313CB0;
      &:before {
        background: url('~assets/images/news/moreArrows1.png') no-repeat center/cover;

      }
    }
  }
  .load {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 16px;
      height: 16px;
      background: url('~assets/images/news/load.png') no-repeat center/cover;
      animation: animationLoad 1.5s linear infinite;
    }
  }
}
@keyframes animationLoad {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media screen and (max-width: @mobileWidth) {
  .news-load {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    .news-load-btn {
      padding: 15px 24px 17px 24px;
      color: #1A1A1A;
      font-size: 12px;
      font-weight: 400;
      line-height: 12px;
    }
    .more {
      position: relative;
      cursor: pointer;
      &:before {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 14px;
        height: 14px;
        background: url('~assets/images/news/moreArrows.png') no-repeat center/cover;
      }
      &:hover {
        color: #313CB0;
        &:before {
          background: url('~assets/images/news/moreArrows1.png') no-repeat center/cover;

        }
      }
    }
    .load {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -7px;
        width: 14px;
        height: 14px;
        background: url('~assets/images/news/load.png') no-repeat center/cover;
        animation: animationLoad 1.5s linear infinite;
      }
    }
  }
}

</style>
