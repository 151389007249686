<template>
  <div class="risk">
    <div class="title">
      {{ $t('风控合规') }}
    </div>
    <div class="card">
      <div class="card-left"></div>
      <div
        class="card-right"
        :class="{ en: lang == 'en' }"
      >
        {{ $t('用户封控前置化，通过自主开发的风险控制模型，即时对每笔款项相关申报材料、真实交易情况、入帐信息等进行匹配审核、为收款业务提供更加综合和前置的风险警示，避免风险延伸。') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'RiskControl',
  computed: {
    ...mapGetters(['lang'])
  }
}
</script>

<style scoped lang="less">
.risk {
  .card {
    display: flex;
    height: 220px;
    margin-top: 60px;
    .card-left {
      width: 615px;
      background: url('~assets/images/compliance/riskLeftBg.png') no-repeat
        center/cover;
    }
    .card-right {
      position: relative;
      display: flex;
      align-items: center;
      width: 555px;
      padding: 0 60px;
      font-size: 15px;
      font-weight: 300;
      color: #ffffff;
      line-height: 30px;
      background-color: #313cb0;
      &.en {
        &::before {
          top: 10px;
        }
      }
      &:before {
        content: '';
        position: absolute;
        left: 15px;
        top: 40px;
        width: 30px;
        height: 30px;
        background: url('~assets/images/compliance/Vector1.png') no-repeat
          center/cover;
      }
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 98px;
        height: 98px;
        background: url('~assets/images/compliance/Vector2.png') no-repeat
          center/cover;
      }
    }
  }
}
@media screen and (max-width: @mobileWidth) {
  .risk {
    padding: 0px;
    .card {
      display: flex;
      flex-wrap: wrap;
      height: 100%;
      margin: 30px auto 0px;
      .card-left {
        width: 100%;
        height: 70px;
        background: url('~assets/images/compliance/riskLeftBg.png') no-repeat
          center/cover;
      }
      .card-right {
        position: relative;
        width: 100%;
        padding: 15px;
        font-size: 12px;
        font-weight: 300;
        color: #ffffff;
        line-height: 24px;
        background-color: #313cb0;
        &:before {
          content: '';
          display: none;
        }
        &:after {
          content: '';
          display: none;
        }
      }
    }
  }
}
</style>
