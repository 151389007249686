<template>
  <div>
    <div class="title">
      {{ $t('安全合规') }}
    </div>
    <div class="card">
      <div
        v-for="(item,index) in columnList"
        :key="index"
        class="card-item"
        :class="[`card-item-img-${index+1}`]"
      >
        <div class="card-item-content">
          <div class="card-item-title">{{ $t(item.title) }}</div>
          <div
            class="card-item-text"
            :class="{en: lang == 'en'}"
          >{{ $t(item.desc) }}</div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Compliance',
  computed: {
    ...mapGetters(['lang'])
  },
  data () {
    return {
      columnList: [
        {
          title: '客户身份安全',
          desc: '我们通过多种身份验证方式，确保客户身份安全'
        },
        {
          title: '系统和数据安全',
          desc: '我们采用多种安全设备用以构建IT安全体系，并通过多种数据备份方案，确保客户的数据安全'
        },
        {
          title: '交易安全',
          desc: '我们建立了多重安全机制并使用专业的加密技术保证客户的交易安全'
        },
        {
          title: 'KYC与反洗钱',
          desc: '我们有经验丰富的合规运营团队，通过专业的反洗钱模型确保银行安全、合规的运营'
        }
      ]
    }
  }
}
</script>

<style scoped lang="less">
.card {
  padding-top: 60px;
  display: flex;
  flex-wrap: wrap;
  .card-item {
    position: relative;
    width: 270px;
    padding-top: 165px;
    background-color: #fff;
    margin-right: 20px;
    border: 1px solid rgba(0, 0, 0, 0.16);
    box-sizing: border-box;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }

    .card-item-content {
      padding: 25px 20px 66px 20px;

      .card-item-title {
        font-size: 20px;
        font-weight: 400;
        color: #000000;
        line-height: 20px;
        padding-bottom: 20px;
      }

      .card-item-text {
        font-size: 14px;
        font-weight: 300;
        color: #1a1a1a;
        line-height: 28px;
      }
    }
  }

  .card-item-img(@name) {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 165px;
    background: url('~assets/images/compliance/@{name}.png') no-repeat center;
    background-size: 100% 100%;
  }
  .card-item-img-1,
  .card-item-img-2,
  .card-item-img-3,
  .card-item-img-4 {
    &:hover {
      &:before {
        transition: background-size 1s;
        background-size: 130% 130%;
      }
    }
  }
  .card-item-img-1:before {
    .card-item-img('secure');
  }

  .card-item-img-2:before {
    .card-item-img('systemData');
  }

  .card-item-img-3:before {
    .card-item-img('transaction');
  }

  .card-item-img-4:before {
    .card-item-img('aml');
  }
}
@media screen and (max-width: @mobileWidth) {
  .card {
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    .card-item {
      position: relative;
      width: 100%;
      height: 130px;
      padding-top: 0;
      padding-left: 110px;
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.16);
      box-sizing: border-box;
      margin-right: 0;
      margin-bottom: 15px;
      .card-item-content {
        padding: 20px 15px;
        .card-item-title {
          font-size: 16px;
          font-weight: 400;
          color: #000000;
          line-height: 16px;
          padding-bottom: 10px;
        }
        .card-item-text {
          font-size: 12px;
          font-weight: 300;
          color: #1a1a1a;
          line-height: 24px;
          &.en {
            line-height: 18px;
          }
        }
      }
    }
    .card-item-img-1,
    .card-item-img-2,
    .card-item-img-3,
    .card-item-img-4 {
      &:hover {
        &:before {
          background-size: cover;
        }
      }
    }

    .card-item-img(@name) {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 110px;
      height: 128px;
      background: url('~assets/images/compliance/@{name}.png') no-repeat
        center/cover;
    }

    .card-item-img-1:before {
      .card-item-img('secure');
    }

    .card-item-img-2:before {
      .card-item-img('systemData');
    }

    .card-item-img-3:before {
      .card-item-img('transaction');
    }

    .card-item-img-4:before {
      .card-item-img('aml');
    }
  }
}
</style>
