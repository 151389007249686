/* eslint-disable */
import CryptoJS from 'crypto-js'
// import router from '@/router'

/**
 * 获取地址中的querystring参数
 * @return object
 */
export const getHrefParams = function () {
  var params = window.location.href.split('?')[1]
  var hrefParams = {}
  if (params) {
    var paramsList = params.split('&')
    for (var i = 0; i < paramsList.length; i++) {
      var item = paramsList[i]
      var tar = item.split('=')
      hrefParams[tar[0]] = tar[1]
    }
  }
  return hrefParams
}

/**
 * 格式化参数
 * @param object
 * @return array
 */
export const formatParams = function (data) {
  var arr = [];
  for (var name in data) {
    arr.push(name + '=' + encodeURIComponent(data[name]));
  }
  return arr.join('&')
}

/**
 * 数组深度flat
 * @param arr 目标数组
 * @param subNode 遍历子节点
 * @return 新数组
 */
export const flatDeep = function (arr, subNode = 'children') {
  let container = []
  for (let i = 0; i < arr.length; i++) {
    const item = arr[i]
    if (item[subNode] && item[subNode].length) {
      container = container.concat(flatDeep(item[subNode]))
    }
    // delete item[subNode]
    container.push(item)
  }
  return container
}

// 将blob转为下载文件或者浏览器中打开
export const downloadBlob = function (blob, config = { filename: '下载', ext: 'xlsx' }) {
  if (!blob) return
  const { filename, ext } = config
  const download = ext ? `${filename}.${ext}` : `${filename}`
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, download)
  } else {
    // 将blob转为blobUrl
    const href = URL.createObjectURL(blob)
    // 创建a标签
    const a = document.createElement('a')
    a.download = download
    a.style.display = 'none'
    a.href = href
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
}

// 将blob在浏览器中打开
export const openBlob = function (blob, type = 'application/pdf') {
  if (!blob) return
  const file = new Blob([blob], { type })
  // IE上不支持直接打开bloburl 它有专门的API
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(file)
  } else {
    const href = URL.createObjectURL(file)
    window.open(href)
  }
}

// 校验值是为为空
export function isEmpty(value) {
  if (value === undefined || value === null || value.length === 0) {
    return true
  }
  return false
}

/**
 * 格式化数字
 * @param {number} number 要格式化的数字
 * @param {string} decimals 保留几位小数
 * @param {string} decPoint 小数点符号
 * @param {string} thousandsSep 千分位符号
 * @param {string} roundtag  舍入参数，默认 "ceil" 向上取,"floor"向下取,"round" 四舍五入
 * @returns {string} 金钱格式字符串 例: $1,000.00
 */
export function numberFormat(number, decimals = 2, decPoint = '.', thousandsSep = ',', roundtag = 'ceil') {
  number = (number + '').replace(/[^0-9+-Ee.]/g, '')
  const n = !isFinite(+number) ? 0 : +number
  const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
  let s = ''
  const toFixedFix = function (n, prec) {
    const k = Math.pow(10, prec)
    return '' + parseFloat(Math[roundtag](parseFloat((n * k).toFixed(prec * 2))).toFixed(prec * 2)) / k
  }
  s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
  const re = /(-?\d+)(\d{3})/
  while (re.test(s[0])) {
    s[0] = s[0].replace(re, '$1' + thousandsSep + '$2')
  }

  if ((s[1] || '').length < prec) {
    s[1] = s[1] || ''
    s[1] += new Array(prec - s[1].length + 1).join('0')
  }
  return s.join(decPoint)
}

// 判断是否是JSON字符串
export const checkIsJSON = (str) => {
  if (typeof str === 'string') {
    try {
      var obj = JSON.parse(str)
      if (typeof obj === 'object' && obj) {
        return true
      } else {
        return false
      }
    } catch (e) {
      return false
    }
  }
  return false
}

/*
  * 加密
*/
export const encryptDes = (message) => {
  const key = CryptoJS.enc.Utf8.parse('9f0d68b8e91209b6')
  const iv = CryptoJS.enc.Utf8.parse('9812jn12aq2599c4')
  let srcs = CryptoJS.enc.Utf8.parse(message);
  let encrypted = CryptoJS.AES.encrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
  return encrypted.toString();
}
/**
 * @description 跳转新页面
 * @param routerName 路由名称
 */
export const toLink = (href) => {
  window.open(href, '_blank')
}

// 校验环境是否移动端
export function isMobile() {
  let flag = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  return flag
}

